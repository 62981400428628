@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl;
    @apply font-bold;
  }

  h2 {
    @apply text-3xl;
    @apply font-bold;
  }

  h3 {
    @apply text-2xl;
    @apply font-bold;
  }

  h4 {
    @apply text-xl;
    @apply font-semibold;
  }

  h5 {
    @apply text-lg;
    @apply font-semibold;
  }

  h6 {
    @apply text-sm;
    @apply font-semibold;
  }
}

@layer utilities {
  .overlay-mask {
    mask-image: linear-gradient(0deg, rgb(255 255 255 / 100%) 0%, rgb(255 255 255 / 0%) 40%);
  }
}

html,
body {
  overscroll-behavior: none;
}

body {
  overflow-y: hidden;
}
